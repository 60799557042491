import type { ProviderGetImage } from '@nuxt/image/dist/module'
import { defu } from 'defu'
import { joinURL, withBase } from 'ufo'
import { createOperationsGenerator } from '@nuxt/image/dist/runtime/utils'
import { base64encode } from '@base/utils/base64'

interface IMGProxyOptions {
  modifiers: Record<string, string>
  baseURL: string
}

export const operationsGenerator = createOperationsGenerator({
  keyMap: {
    fit: 'rt',
    resizing_type: 'rt',
    width: 'w',
    height: 'h',
    dpr: 'dpr',
    enlarge: 'el',
    // extend: 'ex',
    gravity: 'g',
    // crop: 'c',
    // padding: 'pd',
    // trim: 't',
    // rotate: 'rot',
    quality: 'q',
    // max_bytes: 'mb',
    background: 'bg',
    blur: 'bl',
    // sharpen: 'sh',
    // watermark: 'wm',
    // preset: 'pr',
    // cachebuster: 'cb',
    // strip_metadata: 'sm',
    // strip_color_profile: 'scp',
    // auto_rotate: 'ar',
    // filename: 'fn',
    format: 'f',

    // PRO VERSION PARAMS
    // resizing_algorithm: 'ra',
    // background_alpha: 'bga',
    // adjust: 'a',
    // brightness: 'br',
    // contrast: 'co',
    // saturation: 'sa',
    // pixelate: 'pix',
    // unsharpening: 'ush',
    // watermark_url: 'wmu',
    // style: 'st',
    // jpeg_options: 'jpgo',
    // png_options: 'pngo',
    // gif_options: 'gifo',
    // page: 'pg',
    // video_thumbnail_second: 'vts',
  },
  joinWith: '/',
  formatter: (key: string, value: [] | string) => {
    /**
     * Some params can contain multiple parameters (colon separated), like:
     * gravity:%type:%x_offset:%y_offset
     */
    const PARAMS = Array.isArray(value) ? value.join(':') : value
    return `${key}:${PARAMS}`
  },
})

const defaultModifiers = {
  fit: 'contain',
  width: 0,
  height: 0,
  gravity: 'ce',
  enlarge: false,
}

export const getImage: ProviderGetImage = (
  url: string,
  { modifiers = {}, baseURL = '/imgproxy' }: IMGProxyOptions,
) => {
  const mergedModifiers = defu(modifiers, defaultModifiers)
  switch (mergedModifiers.fit) {
    case 'cover':
      mergedModifiers.fit = 'fill'
      break
    case 'contain':
      mergedModifiers.fit = 'fit'
      break
    case 'fill':
      mergedModifiers.fit = 'force'
      break
    case 'inside':
      mergedModifiers.fit = 'auto'
      break
    case 'outside':
      mergedModifiers.fit = 'auto'
      break
  }

  const path = joinURL(
    '/_',
    operationsGenerator(mergedModifiers),
    base64encode(url, 'base64'),
  )

  return {
    url: withBase(path, baseURL),
  }
}
